import React from "react"
import { Link } from "gatsby";
import Footer from "../components/Footer";
import SEO from "../components/seo";

export default function Home() {
  return <div className="Home">
    <SEO />
    <nav className="Navbar">
      <Link to="/">
        <h3 className="logo">{'<stm/>'}</h3>
      </Link>
    </nav>
    <section className="introduction">
      <p className="my-name">Hi, I'm Scotty McCormick</p>
    </section>

    <section className="about-me">
      <p>I am a web developer with 3+ years of experience.</p>
      <p>Building sites with React, Express, Node is my day-to-day gig.</p>
      <p>Lately I've been using Next.js, Redux, Less, GTM, PreBid, MongoDB.</p>
      <p>My work is about 75% front end, with some basic AWS management, data fetching, and dealing with third-party pixels as well</p>
      <p>In my personal projects, I've been focused on Next.js, Tailwind, and brushing up on my SQL DB querying.</p>
      <p>This site is (recently) rebuilt in Gatsby and deployed on Netlify</p>
      <div className="about-link-wrapper">
        <Link to="/about/">More about me</Link>
      </div>
    </section>
    <Footer />
  </div>
}
